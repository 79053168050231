import { type AnalyticsManager } from "services/analytics/analyticsManager";
import { type IssuesStore } from "services/issues/issuesStore";
import type { AbnQuestionsStore } from "services/questions/au/abnQuestionsStore";
import { type CompanyQuestionsStore } from "services/questions/au/companyQuestionsStore";
import { type DirectorQuestionsStore } from "services/questions/au/directorQuestionsStore";
import { RegisterFormStep } from "services/registerForm/registerFormStep";
import { RegisterFormSteps } from "services/registerForm/registerFormSteps";
import { RegisterFormStepsStoreBase } from "services/registerForm/registerFormStepsStoreBase";
import { AcnFormStep } from "./constants";

export class AcnRegisterFormStepsStore extends RegisterFormStepsStoreBase<AcnFormStep> {
  protected readonly _steps: RegisterFormSteps<AcnFormStep> = new RegisterFormSteps<AcnFormStep>([
    new RegisterFormStep(AcnFormStep.HOW_IT_WORKS, "Before you get started"),
    new RegisterFormStep(AcnFormStep.CONSENT, "Before you get started"),
    new RegisterFormStep(AcnFormStep.COMPANY, "Company details", this._companyQuestionsStore),
    new RegisterFormStep(AcnFormStep.ABN, "ABN details", this._abnQuestionsStore),
    new RegisterFormStep(AcnFormStep.DIRECTOR, "Business associates", this._directorQuestionsStore),
    new RegisterFormStep(AcnFormStep.BASKET, "Your basket"),
    new RegisterFormStep(AcnFormStep.CONFIRM, "Confirm and submit"),
    new RegisterFormStep(AcnFormStep.CHARGE, "Confirm and submit"),
    new RegisterFormStep(AcnFormStep.COMPLETE, "Complete"),
  ]);
  readonly confirmStep: AcnFormStep = AcnFormStep.CONFIRM;
  readonly competeStep: AcnFormStep = AcnFormStep.COMPLETE;

  constructor(
    private readonly _companyQuestionsStore: CompanyQuestionsStore,
    private readonly _abnQuestionsStore: AbnQuestionsStore,
    private readonly _directorQuestionsStore: DirectorQuestionsStore,
    protected readonly _issuesStore: IssuesStore,
    protected readonly _analyticsManager: AnalyticsManager,
  ) {
    super(_issuesStore, _analyticsManager);
  }
}
