import { formatMoney } from "helpers/money";
import { BillingItem } from "services/checkoutBasket/checkoutBasketStore";
import { VirtualOfficeBillingPeriod } from "services/companyProducts/companyProductsTypes";

export interface BillingItemData {
  title: string;
  price: string;
  priceText?: string;
  priceTotal?: string;
  vat?: string;
  vatTotal?: string;
  description: string;
  discount?: string;
  discountPrice?: string;
  remark?: string;
  featuresList?: string[];
}

export class BillingItemsStore {
  getData(billingItem: BillingItem, param?: unknown): BillingItemData {
    switch (billingItem) {
      case BillingItem.VirtualOffice: {
        return this._getVirtualOfficeData(param as VirtualOfficeBillingPeriod);
      }
      case BillingItem.Taxes:
        return {
          title: "+Taxes",
          description: "Take the stress out of tax admin by letting ANNA + Taxes do the work for you",
          price: `${formatMoney(3, { monthly: true })}, for 3 months`,
          vat: formatMoney(0.6),
          remark: `${formatMoney(24)} after that`,
        };
      case BillingItem.VatRegistration:
        return {
          title: "VAT registration with HMRC",
          description: "On demand VAT Registration on your behalf",
          price: formatMoney(19.5),
          vat: formatMoney(3.9),
        };
      case BillingItem.ConfirmationStatement:
        return {
          title: "Confirmation statement filing",
          description:
            "ANNA can help you to file your company’s confirmation statement (annual return) " +
            "in time for the deadline for you",
          price: formatMoney(44.9),
          vat: formatMoney(8.98),
        };
      case BillingItem.CancellationCover:
        return {
          title: "Company cancellation cover",
          description:
            "Have you changed mind and decided you no longer need your company? " +
            "We’ll take care of the paperwork and close the company for you",
          price: formatMoney(24.99),
          vat: formatMoney(5.0),
        };
      case BillingItem.PayeRegistration:
        return {
          title: "PAYE Employer registration",
          description:
            "Want to pay a salary to yourself or company employees? " +
            "Let us register your company for PAYE with HMRC",
          price: formatMoney(12.9),
          vat: formatMoney(2.58),
        };
      case BillingItem.FormationExpress:
        return {
          title: "Formation express",
          description: "Same day company registration",
          price: formatMoney(59.8),
          vat: formatMoney(11.96),
        };
    }
  }

  private _getVirtualOfficeData(
    billingPeriod: VirtualOfficeBillingPeriod = VirtualOfficeBillingPeriod.Monthly,
  ): BillingItemData {
    const prices = {
      [VirtualOfficeBillingPeriod.Monthly]: {
        price: formatMoney(14.99, { monthly: true }),
        vat: formatMoney(3),
      },
      [VirtualOfficeBillingPeriod.Yearly]: {
        price: formatMoney(7.5, { monthly: true }),
        vat: formatMoney(1.5),
      },
    };
    const price = prices[billingPeriod].price;
    const vat = prices[billingPeriod].vat;
    const priceSuffix = ", billed " + (billingPeriod === VirtualOfficeBillingPeriod.Monthly ? "monthly" : "annually");

    return {
      title: "Virtual office address",
      description: "Keep your personal address private with same day scanning and mail forwarding",
      price,
      priceText: price + priceSuffix,
      vat,
      priceTotal: billingPeriod === VirtualOfficeBillingPeriod.Yearly ? formatMoney(90) + "/year" : undefined,
      vatTotal: billingPeriod === VirtualOfficeBillingPeriod.Yearly ? formatMoney(18) : undefined,
    };
  }
}
