import { type FC, Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Loader } from "components/Loader/Loader";

const Element = lazy(async () => await import("../../components/SignUp/au/SignUp"));

export const SignUpRoutes: FC = () => {
  return (
    <Routes>
      <Route
        index
        path="*"
        element={
          <Suspense fallback={<Loader size="l" />}>
            <Element />
          </Suspense>
        }
      />
    </Routes>
  );
};
